import React from "react";
import Hero from "./Sections/Home/Hero";
import AboutSection from "./Sections/Home/AboutSection";
import KeyFeatures from "./Sections/Home/KeyFeatures";
import PatientEngagementJourney from "./Sections/Home/PatientEngagementJourney";
import Ctabottom from "./Sections/Common/Ctabottom";
import Testimonials from "./Sections/Common/Testimonials";
import Section from "../../Section";
import Cta from "../../Cta";
import BannerSectionStyle4 from "../../Section/BannerSection/BannerSectionStyle4";
import BlogSectionStyle5 from "../../Section/BlogSection/BlogSectionStyle5";
import { pageTitle } from "../../../helpers/PageTitle";

const KeyFeaturesData = [
  {
    title: "Streamlined Patient Intake",
    subTitle:
      "Januity's Streamlined Patient Intake revolutionizes the admission process following the HIPAA compliancy, ensuring swift, protected, accurate, and hassle-free patient onboarding.",
    iconUrl: "images/home_1/patient-intake.svg",
    href: "/solutions/#streamline-registration",
  },
  {
    title: "Clinic Staff Optimization",
    subTitle:
      "Januity's Clinic Staff Optimization enhances healthcare efficiency, enabling professionals to adapt to evolving patient needs and maximize care quality.",
    iconUrl: "images/home_1/workflow.svg",
    href: "/solutions/#dynamic-optimization",
  },
  {
    title: "Kiosk Connect",
    subTitle:
      "Januity's Kiosk Connect efficiently gathers clinical data from patients and integrates it into electronic medical records (EMRs) via a cloud-based infrastructure, enhancing healthcare efficiency.",
    iconUrl: "images/home_1/kiosk.svg",
    href: "/solutions/#kiosk-connect",
  },
  {
    title: "Integrated Health Records",
    subTitle:
      "Januity's Integrated Health Records provide comprehensive, real-time access to patient information, enhancing care coordination and clinical decision-making.",
    iconUrl: "images/home_1/medicalrecord.svg",
    href: "/solutions/#ihr",
  },
  {
    title: "Smart Appointment Scheduling",
    subTitle:
      "Januity's enhanced Appointment Scheduling optimizes patient and clinician time management, reducing no-shows and ensuring a seamless and efficient booking process.",
    iconUrl: "images/home_1/appointments.svg",
    href: "/solutions/#smart-scheduling",
  },
  {
    title: "Januity Insights",
    subTitle:
    "Januity's insights empower healthcare professionals with actionable insights, driving continuous improvement and innovation in patient care delivery",
    iconUrl: "images/home_1/report.svg",
    href: "/solutions/#januity-insights",
  },
  {
    title: "Patient-Centered Satisfaction Solution",
    subTitle:
      "Januity enhances patient experiences with streamlined check-ins and reduced lobby wait times, ensuring a more efficient healthcare journey.",
    iconUrl: "images/home_1/heart care.svg",
    href: "/solutions/#patient-satisfaction",
  },
  {
    title: "Januity CareConnect",
    subTitle:"Januity CareConnect enables virtual consultations and remote monitoring, ensuring accessible, high-quality care from any location.",
    iconUrl: "images/home_1/laptop.svg",
    href: "/solutions/#care-connect",
  }
];

const PatientEngagementJourneyData = [
  {
    title: "1. Pre-Appointment",
    content:
      "<ul><li>Clinical Questions by Chatbot</li><li>Clinical Questions by Chatbot</li><li>Clinical Questions by Chatbot</li><li>Clinical Questions by Chatbot</li><li>Clinical Questions by Chatbot</li><li>Clinical Questions by Chatbot</li><li>Clinical Questions by Chatbot</li><li>Clinical Questions by Chatbot</li><li>Clinical Questions by Chatbot</li><li>Clinical Questions by Chatbot</li></ul>",
  },

  {
    title: "2. During Visit",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
  {
    title: "3. After Visit",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
  {
    title: "4. Between Visits",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
];

const blogData = [
  {
    title: "The Benefits of Mindfulness Meditation for Stress and Anxiety",
    thumbUrl: "/images/home_4/post_1.jpeg",
    category: "Mental Health",
    date: "September 12, 2023",
    href: "/blog/blog-details",
  },
  {
    title: "The Importance of Regular Cancer Screenings and Early Detection",
    thumbUrl: "/images/home_4/post_2.jpeg",
    category: "Education",
    date: "September 07, 2023",
    href: "/blog/blog-details",
  },
  {
    title:
      "Managing Chronic Pain: Tips and Strategies for a Better Quality of Life",
    thumbUrl: "/images/home_4/post_3.jpeg",
    category: "Healthy Life",
    date: "September 01, 2023",
    href: "/blog/blog-details",
  },
];

const testimonialData = [
  {
    text: '"I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease.”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_2.png",
    avatarName: "Sophia Torres",
    avatarDesignation: "New York, USA",
  },
  {
    text: '"ProHealth has been a game-changer for me. The doctors are caring and highly skilled, and the staff is always friendly and accommodating. We hope for the best wishes.”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_3.png",
    avatarName: "John Dupont",
    avatarDesignation: "Manhattan, USA",
  },
  {
    text: "“I had a great experience with ProHealth. The staff were friendly and professional, and the doctor took the time to answer all my questions and address my concerns.”",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_4.png",
    avatarName: "Allen Duarte",
    avatarDesignation: "California, USA",
  },
  {
    text: '"I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease.”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_2.png",
    avatarName: "Sophia Torres",
    avatarDesignation: "New York, USA",
  },
  {
    text: '"ProHealth has been a game-changer for me. The doctors are caring and highly skilled, and the staff is always friendly and accommodating. We hope for the best wishes.”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_3.png",
    avatarName: "John Dupont",
    avatarDesignation: "Manhattan, USA",
  },
  {
    text: "“I had a great experience with ProHealth. The staff were friendly and professional, and the doctor took the time to answer all my questions and address my concerns.”",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_4.png",
    avatarName: "Allen Duarte",
    avatarDesignation: "California, USA",
  },
];
export default function Home() {
  pageTitle("Home");
  return (
    <>
      <Hero
        title="Revolutionizing Patient Care"
        subTitle="By Streamlining Clinics’ Intake and Workflow Through Enhanced Data Collection And Analysis"
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img1.png"
        btnText="See how we work"
        btnUrl="/our-approach"
        infoList={[
          {
            title: "Hotline",
            subTitle: "123-456-7890",
            iconUrl: "/images/contact/icon_1.svg",
          },
          {
            title: "Ambulance",
            subTitle: "876-256-876",
            iconUrl: "/images/icons/ambulance.svg",
          },
          {
            title: "Location",
            subTitle: "New York, US",
            iconUrl: "/images/icons/pin.svg",
          },
        ]}
      />

      <Section bottomMd={100} bottomLg={100} bottomXl={100}>
        <AboutSection
          imgUrl="/images/home/kiosk.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="Innovators in Healthcare Data Solutions"
          subTitle="Who We Are"
          featureList={[
            {
              featureListTitle:
                "Januity Healthcare integrates patient clinical data into EMRs, tackling healthcare inefficiencies with leading-edge technology solutions.",
              featureListSubTitle:
                "Our comprehensive approach encompasses everything from automated patient intake to physician support tools, ensuring a smooth and efficient workflow for both patients and providers. Think of us as your strategic partner in optimizing healthcare facilities.",
            },
          ]}
        />
      </Section>

      <Section
        topMd={100}
        topLg={100}
        topXl={100}
        bottomMd={100}
        bottomLg={100}
        bottomXl={100}
        id="KeyFeatures"
        className="cs_gray_bg_1"
      >
        <KeyFeatures
          sectionTitle="Exclusive Features for <br/>Efficient Healthcare Management"
          sectionTitleUp="Key Features"
          data={KeyFeaturesData}
        />
      </Section>

      {/* <Section
        topMd={190}
        topLg={150}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <Testimonials
          sectionTitle="Discover the Stories of Health and Healing"
          sectionTitleUp="WHAT OUR PATIENTS SAY"
          avatarListTitle="150+ patients are reviews about ProHealth"
          avatarList={[
            { imgUrl: '/images/home_4/avatar_1.png' },
            { imgUrl: '/images/home_4/avatar_2.png' },
            { imgUrl: '/images/home_4/avatar_3.png' },
            { imgUrl: '/images/home_4/avatar_4.png' },
            { imgUrl: '/images/home_4/avatar_5.png' },
          ]}
          data={testimonialData}
        />
      </Section> */}

      <Section
        topMd={100}
        topLg={100}
        topXl={100}
        bottomMd={100}
        bottomLg={100}
        bottomXl={100}
        className="cs_appointment_section_1 cs_bg_filed"
        style={{
          backgroundImage: `url('/images/home_2/appointment_bg.svg')`,
        }}
      >
        <PatientEngagementJourney
          sectionTitle="Enagement Flow for Patient Care"
          sectionSubTitle=""
          sectionTitleUp="Patient Journey"
          data={PatientEngagementJourneyData}
        />
      </Section>
      <Ctabottom />
      {/* <Section
        topMd={185}
        topLg={145}
        topXl={105}
        bottomMd={160}
        bottomLg={110}
        bottomXl={80}
      >
        <BlogSectionStyle5
          sectionTitle="Our Latest Blog"
          sectionTitleUp=""
          sectionTitleDown=""
          sectionSubTitle=""
          data={blogData}
        />
      </Section> */}
      
    </>
  );
}
