import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import Section from '../Section';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
import AboutSectionStyle5 from '../Section/AboutSection/AboutSectionStyle5';
import FunFactSection from '../Section/FunFactSection';
import FaqSection from '../Section/FaqSection';
import TeamSection from '../Section/TeamSection';
import GallerySection from '../Section/GallerySection';
import WorkingProcess from '../Section/WorkingProcess';
import AwardSectionStyle2 from '../Section/AwardSection/AwardSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';
const departmentData = [
  {
    title: 'Our Mission',
    subTitle:
      'We are dedicated to revolutionizing healthcare by providing innovative data solutions that streamline workflows, enhance patient care, and drive revenue growth for healthcare providers.',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Our Vision',
    subTitle:
      'Our vision is to automate healthcare processes, reduce redundancies, and optimize efficiency, ultimately leading to improved patient outcomes and reduced healthcare costs.',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  
];
const faqData = [
  {
    title: 'What benefits does healthcare data analytics offer?',
    content:
      'Healthcare data analytics offers numerous benefits, including improved patient outcomes, enhanced operational efficiency, better resource allocation, personalized patient care, and cost savings for healthcare providers.',
  },
  {
    title: 'How does the kiosk machine work?',
    content:
      'The kiosk machine functions by efficiently gathering clinical data from patients, which is then seamlessly integrated into electronic medical records (EMRs) through a cloud-based infrastructure. This process enables streamlined data collection and transfer, ultimately improving the overall efficiency of healthcare operations.',
  },
  {
    title: 'How is patient data secured and protected?',
    content:
      'Patient data is secured and protected through robust security measures such as encryption, access controls, and continuous monitoring. These measures ensure the confidentiality and integrity of patient information, safeguarding it against evolving cyber threats and complying with industry regulations like HIPAA and GDPR.',
  },
  {
    title: 'What types of insights can be gained from the collected data?',
    content:
      'The collected data provides valuable insights into various aspects of healthcare, including patient demographics, treatment outcomes, resource utilization, trends in disease prevalence, and quality of care metrics. These insights enable healthcare providers to make informed decisions, identify areas for improvement, and optimize their workflows for better patient care.',
  },
  {
    title: 'How does patient portability enhance healthcare delivery?',
    content:
      'Patient portability enhances healthcare by enabling seamless access to patient data across different healthcare settings and providers. This facilitates continuity of care, reduces duplication of tests and procedures, minimizes medical errors, improves care coordination, and ultimately leads to better health outcomes for patients.',
  },
];
const workingProcessData = [
  {
    title: 'Efficient Data Collection',
    subTitle:
      'Utilize our intuitive platform to collect patient information securely and accurately.',
    iconUrl: '/images/home_2/wording_process_icon_1.svg',
    number: '01',
  },
  {
    title: 'Secure Data Storage',
    subTitle:
      'Data seamlessly flows into our cloud infrastructure, ensuring privacy and compliance.',
    iconUrl: '/images/home_2/wording_process_icon_2.svg',
    number: '02',
  },
  {
    title: 'Advanced Analytics',
    subTitle:
      'Harness the power of data with our analytics tools to gain valuable insights.',
    iconUrl: '/images/home_2/wording_process_icon_3.svg',
    number: '03',
  },
  {
    title: 'Integration with EMR',
    subTitle:
      'Efficient integration with electronic medical records for seamless data transfer.',
    iconUrl: '/images/home_2/wording_process_icon_4.svg',
    number: '04',
  },
  
];
const featureListData = [
  {
    title: 'Experienced Medical Professionals',
    subTitle:
      'Our team includes experienced doctors, nurses, <br />and other healthcare professionals who are <br />dedicated to providing the best possible care to <br />our patients.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Comprehensive <br />Services',
    subTitle:
      'We offer a wide range of healthcare services, <br />from preventive care to specialized treatment <br />for complex conditions.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Patient-centered <br />Approach',
    subTitle:
      'We believe in treating each patient as an <br />individual, and we take the time to understand <br />your unique health needs and concerns.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'State-of-the-art <br />Facilities',
    subTitle:
      'Our healthcare center is equipped with the <br />latest technology and equipment to provide our <br />patients with the most advanced care possible.',
    iconUrl: 'images/icons/facilities.svg',
  },
];

const funFactData = [
  { number: '20+', title: 'Years of experience' },
  { number: '95%', title: 'Patient satisfaction rating' },
  { number: '5000+', title: 'Patients served annually' },
  { number: '10+', title: 'Healthcare providers on staff' },
  { number: '22+', title: 'Convenient locations in the area' },
];

const teamData = [
  {
    imgUrl: 'images/about/doctor_1.png',
    name: 'Dr. James Lee, MD',
    designation: 'Head of Cardiologist',
    description:
      'With expertise in managing complex heart conditions and performing advanced cardiac procedures',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_2.png',
    name: 'Dr. John Smith, MD',
    designation: 'Emergency Medicine Physician',
    description:
      'With expertise in treating acute illnesses and injuries in medicine physician',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_3.png',
    name: 'Dr. Susan Bones, MD',
    designation: 'Board-certified Pediatrician',
    description:
      'With experience in managing complex medical conditions in children',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
];

const galleryData = [
  { imgUrl: '/images/about/portfolio_2_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_3_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_1_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_4_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_5_lg.jpeg' },
];
const awardData = [
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Malcolm Baldrige National Quality Award',
  },
  { iconUrl: '/images/icons/award.svg', title: 'HIMSS Davies Award' },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Healthgrades National’s Best Hospital',
  },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Joint Commission Gold Seal of Approval',
  },
];

export default function Faq() {
  pageTitle('About');
  return (
    <>
      <Section topMd={190} topLg={200} topXl={200} bottomMd={200} bottomLg={150} bottomXl={110}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>
    </>
  );
}
