import React from 'react';
import PageBanner from './Sections/Common/PageBanner';
import OurStory from './Sections/About/OurStory';
import VisionMission from './Sections/About/VisionMission'
import OurTeam from './Sections/About/OurTeam';
import Ctabottom from "./Sections/Common/Ctabottom";
import BannerSectionStyle4 from '../../Section/BannerSection/BannerSectionStyle4';
import Section from '../../Section';

import { pageTitle } from '../../../helpers/PageTitle';

const visionmissionData = [
  {
    title: 'Our Mission',
    subTitle:
      'We are dedicated to revolutionizing healthcare by providing innovative data solutions that streamline workflows, enhance patient care, and drive revenue growth for healthcare providers.',
    iconUrl: '/images/icons/tick.svg',
    href: '/ourmissionandvision/#mission',
  },
  {
    title: 'Our Vision',
    subTitle:
      'Our vision is to automate healthcare processes, reduce redundancies, and optimize efficiency, ultimately leading to improved patient outcomes and reduced healthcare costs.',
    iconUrl: '/images/icons/quote.svg',
    href: '/ourmissionandvision/#vision',
  },
];

const featureListData = [
  {
    title: 'Experienced Medical Professionals',
    subTitle:
      'Our team includes experienced doctors, nurses, <br />and other healthcare professionals who are <br />dedicated to providing the best possible care to <br />our patients.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Comprehensive <br />Services',
    subTitle:
      'We offer a wide range of healthcare services, <br />from preventive care to specialized treatment <br />for complex conditions.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Patient-centered <br />Approach',
    subTitle:
      'We believe in treating each patient as an <br />individual, and we take the time to understand <br />your unique health needs and concerns.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'State-of-the-art <br />Facilities',
    subTitle:
      'Our healthcare center is equipped with the <br />latest technology and equipment to provide our <br />patients with the most advanced care possible.',
    iconUrl: 'images/icons/facilities.svg',
  },
];

const teamData = [
  {
    
    imgUrl: '/images/doctors/dinh.png',social: [
      { icon: 'fa6-brands:linkedin-in', href: 'https://www.linkedin.com/in/dinh-tran-md-64282859/' },
    ],
    name: 'Dinh Tran',
    designation: 'Chief Executive Officer, Founder',
  //   description:
  //     'Dinh is a seasoned professional with a decade of clinical expertise, seamlessly blending the realms of healthcare and technical innovation. As a dedicated primary care physician, Dinh’s rich experience working with diverse patients has afforded him a unique vantage point, revealing common challenges as potential avenues for progress. Residing in Seattle, he brings a wealth of industry insight to the table, leveraging his background in medical residency training at Wayne State University/Detroit Medical Center. Passionate about fostering systemwide solutions, Dinh is committed to enhancing the healthcare landscape for all stakeholders, with a particular focus on empowering both patients and clinicians.',
  // 
  },
  {
    imgUrl: '/images/doctors/bob.png', social: [
      { icon: 'fa6-brands:linkedin-in', href: 'https://www.linkedin.com/in/bobthongmd/' },
    ],
    name: 'Bob Thong',
    designation: 'Chief Operating Officer, Founder',
  //   description:
  //     'Bob is a health technology enthusiast. Before Januity, he was an established practicing physician specializing in hospital and senior care medicine. Flourishing at the crossroads of medicine and technology, he has made a meaningful contribution as a clinical instructor at the UW School of Medicine, enriching medical education. In the Seattle angel investing community, he co-founded APIS Health Angels, striving for transparency in the early-stage capital process. He brings a unique blend of medical expertise and entrepreneurial insight to advising health tech startups. Bob’s dedication extends to advocating for elder care within the long-term care space, showcasing his commitment to comprehensive healthcare and community well-being.',
  // 
},
  {
    imgUrl: '/images/doctors/shergil.png', social: [
      { icon: 'fa6-brands:linkedin-in', href: 'https://www.linkedin.com/in/ashergill/' },
    ],
    name: 'AJ Shergill',
    designation: 'Chief Medical Officer, Founder',
  //   description:
  //     'AJ, a veteran physician, brings over 15 years of rich experience to the healthcare and technology landscape. Starting as a hospital-based physician, he ascended to the role of associate medical director before transitioning to private practice, founding a successful medspa, and directing a 100-bed skilled nursing facility. AJ discovered his clinical passion in addressing addiction and mental health disorders within underserved communities, leading him to establish clinics across multiple states. Amid the pandemic, he advised health tech companies and fellow physicians on integrating emerging technologies. Driven by a commitment to enhance care delivery, he co-founded Januity, leveraging his diverse clinical background, business acumen, and insights into workflows to streamline processes and alleviate administrative burdens on physicians. For AJ, Januity represents a profound second calling, poised to positively impact healthcare delivery nationwide.', 
},
  {
    imgUrl: '/images/doctors/cto.png',social: [
      { icon: 'fa6-brands:linkedin-in', href: 'https://www.linkedin.com/in/sreevadde/' }
    ],
    name: 'Sree Vadde',
    designation: 'Chief Product & Technology Officer',
  //   description:
  //     'Sree is a seasoned leader with 20+ years of experience in product and technology, proficient in developing and executing strategic vision and roadmaps. He led transformation of product portfolios and scaling operations for several organizations. Sree has been instrumental in launching cutting-edge solutions in HR Tech, Travel Tech, FinTech, and Healthcare, harnessing advanced technologies such as AI, cloud computing, cybersecurity, and software development to elevate organizational innovation, efficiency, and growth. Sree led the building of innovative HR tech products along with foundational platforms like the Oracle Rules Engine and SPG Mobile Key. Sree has also forged significant collaborations with tech giants, developing the NDCC platform with Microsoft and partnering with Amazon, Facebook, and Google to create a flexible travel platform. ',
  // 
  },
  {
    imgUrl: '/images/doctors/cmo.png', social: [
      { icon: 'fa6-brands:linkedin-in', href: 'https://www.linkedin.com/in/nathan-dale-1a532a8/' }
    ],
    name: 'Nanthan Dale',
    designation: 'Chief Mechanical Engineer',
  //   description:
  //     'With 27 years of expertise spanning the aerospace, consumer goods/electronics, and medical device industries, Nate is an exceptionally skilled professional renowned for his mastery in product research, design, and development, coupled with adept project management acumen. His illustrious career includes the successful launch of a myriad of products, from implantable medical devices and surgical tools to cutting-edge wearable insulin delivery devices and innovative coffee makers for Starbucks. Specializing in injection mold design, flex circuit design, and medical-grade materials selection, Nate’s proficiency extends to clean room design, validation, and medical device process verification. Having served prestigious clients such as Boeing Aerospace, Microsoft, Nike, and St. Jude Medical, Nate’s remarkable achievements are underscored by 25 US patents and the 2011 Medical Design Excellence Award for the Pleura Flow active clearance system. His commitment to excellence is exemplified through his role in multiple start-ups, where he has successfully navigated products through the entire development cycle and FDA 510(K) submission process.',
  // 
  },
  {
    imgUrl: '/images/doctors/dipu.jpg',  social: [
      { icon: 'fa6-brands:linkedin-in', href: 'https://www.linkedin.com/in/dipugee' },
    ],
    name: 'Dipankar Ganguly',
    designation: 'Chief Strategic Advisor',
  //   description:
  //     'Dipu, our accomplished innovator, is a strategic healthcare leader with a remarkable legacy as the inventor and developer of the BladderScanner, setting the standard of care for bladder management with groundbreaking contributions in the field. With a proven track record of building and steering valuable companies at the forefront of pioneering technologies, Dipu specializes in digital health, wearables, and point-of-care devices across diverse medical fields such as diagnostic imaging, urology, ob-gyn, cardiology, oncology, neurology, and combined drug-device applications. With a keen focus on clinical and market needs, Dipu is passionate about translating scientific discoveries into the next generation of impactful health technology products. Renowned for creating market-driven Class I/II/III medical products, this high-bandwidth, hands-on innovator combines expertise in sensing, signal/image/data processing, and ML software-based devices with mobile technologies and cloud-based analytics. A pioneer in transforming research-focused teams into effective QSR-compliant product development organizations, Dipu has a strong history of attracting significant equity investments or orchestrating successful acquisitions.',
  // 
  },
  {
    imgUrl: '/images/doctors/cfo.png', social: [
      { icon: 'fa6-brands:linkedin-in', href: 'https://www.linkedin.com/in/mai-duong-cpa-405758b/' },
    ],
    name: 'Mai Duong',
    designation: 'Chief Financial Advisor',
    // description:
      // 'Mai is a dynamic and multi-lingual CPA with an MBA from Cornell and Executive Education in M&A from Harvard Business School, bringing over 24 years of invaluable experience in successfully leading complex financial and accounting functions. As a subject matter expert, Mai specializes in SEC Fin Reporting, Technical Accounting, SOX & FCPA, strategy & succession planning, consolidation & accounting month end close, share-based comp, internal reporting, financial review and planning, business process improvement, Valuation and due diligence for M&A transactions. Proficient in various ERP systems, SEC filing software, and treasury processes, Mai has navigated the intricacies of private companies, subsidiaries of public companies, and the IPO process. Mai’s expertise spans diverse industries, including Software, Oil & Gas full spectrum, High-tech manufacturing (MFG), Percent Completion (POC), Master Limited Partnership (MLP), Common control accounting, Saas, and insurance. A strong advocate for Kaizen & Lean Management, Mai drives process improvements and accelerated Month end Close using cutting-edge cloud-based tools.',
    
  },

];


export default function About() {
  pageTitle('About');
  return (
    <>
      <PageBanner
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/solution-banner.png"
        title="Revolutionizing the Way We Approach Wellness<br />"
        subTitle="Pioneering transformative healthcare access through cutting-edge technology and analytics."
      />

      <Section topMd={200} topLg={150} topXl={110}>
        <VisionMission
          sectionTitle="Streamlining Patient Intake for Optimal Efficiency"
          sectionTitleUp="Introducing Januity"
          data={visionmissionData}
        />
      </Section>

      <Section topMd={170} topLg={120} topXl={100}>
        <OurStory
          imgUrl="/images/home_4/about_img.jpeg"
          titleUp="ABOUT US"
          title="Innovators in Healthcare Data Solutions"
          subTitle="At Januity Healthcare, we leverage technology to eliminate inefficiencies in healthcare. As a leading health tech company, we provide solutions to gather patient data and integrate it into electronic medical records. Our services, from automated intake to physician support tools, ensure a seamless workflow for patients and providers. We are your partner in streamlining operations and enhancing the healthcare experience."
          supportTitle="Tech-Driven Healthcare Transformation"
        />
      </Section>

      <Section topMd={190} topLg={145} topXl={105}>
        <OurTeam
          sectionTitle="Experts in Healthcare and Technology"
          sectionTitleUp="Our Team"
          data={teamData}
        />
      </Section>
     
      <Ctabottom />
    </>
  );
}
