import React from 'react';
import PageBanner from './Sections/Common/PageBanner';
import TimelineView from './Sections/OurApproach';
import Ctabottom from "./Sections/Common/Ctabottom";
import BannerSectionStyle4 from '../../Section/BannerSection/BannerSectionStyle4';
import Section from '../../Section';
import { pageTitle } from '../../../helpers/PageTitle';


const timelineData = [
  {
    title: 'Efficient Data Collection',
    subTitle:
      'Utilize our intuitive platform to collect patient information securely and accurately.',
    iconUrl: '/images/home_2/wording_process_icon_1.svg',
    number: '01',
  },
  {
    title: 'Secure Data Storage',
    subTitle:
      'Data seamlessly flows into our cloud infrastructure, ensuring privacy and compliance.',
    iconUrl: '/images/home_2/wording_process_icon_2.svg',
    number: '02',
  },
  {
    title: 'Advanced Analytics',
    subTitle:
      'Harness the power of data with our analytics tools to gain valuable insights.',
    iconUrl: '/images/home_2/wording_process_icon_3.svg',
    number: '03',
  },
  {
    title: 'Integration with EMR',
    subTitle:
      'Efficient integration with electronic medical records for seamless data transfer.',
    iconUrl: '/images/home_2/wording_process_icon_4.svg',
    number: '04',
  },
  
];

export default function OurApproach() {
  pageTitle('About');
  return (
    <>

      <PageBanner
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/our-approach.png"
        title="Our Approach"
        subTitle="Building an Integrated Infrastructure for Actionable Data Insights"
      />

      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TimelineView
          sectionTitle="Januity Workflow"
          sectionTitleUp=""
          sectionTitleDown=""
          sectionSubTitle="Building an Integrated Infrastructure for Actionable Data Insights"
          data={timelineData}
        />
      </Section>
     
      
      <Ctabottom />
    </>
  );
}
