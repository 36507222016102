import React from 'react';
import Spacing from '../../../../Spacing';
import Section from "../../../../Section";
export default function SolutionsL({
  imgUrl,
  spiningImgUrl,
  title,
  subTitle,
  featureList,
}) {
  return (
    <section className="cs_about cs_shape_wrap cs_style_1 pb-5 cs_gray_bg_1">
    
      <div className="container">
        <div className="row align-items-center flex-lg-row flex-column-reverse cs_gap_y_40">
        <div className="col-lg-7 order-md-1 order-lg-1 order-2">
            <Spacing md="55" />
            <div className="cs_about_img">
              <img src={imgUrl} alt="About" />
              <div className="cs_about_mini_img">
                {/* <img
                  src={spiningImgUrl}
                  alt="Mini"
                  className="cs_rotate_animation"
                /> */}
                <img
                  src="/images/icons/tick.svg"
                  alt="Right"
                  className="cs_right_icon"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5 order-md-2 order-lg-2  order-1">
            <div className="cs_about_content">
              <div className="cs_section_heading cs_style_1">
                <h4 className="cs_section_title cs_fs_40 m-0">{title}</h4>
                <div className="cs_height_5" />
            
              </div>
              <Spacing md="40" lg="40" />
              <ul className="cs_about_text_list">
                {featureList.map((item, index) => (
                  <li key={index}>
                    <p className="m-0" dangerouslySetInnerHTML={{ __html: item.featureListSubTitle}}></p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          

        </div>
      </div>
    </section>
  );
}
