import React from 'react';
import parse from 'html-react-parser';
import Button from '../../../../Button';
import Spacing from '../../../../Spacing';
import Section from "../../../../Section";
import Cta from "../../../../Cta";
export default function Ctabottom({

}) {
  return (
    <Section bottomMd={160}  topMd={100}  topLg={100}  topXl={100} bottomLg={110} bottomXl={80}>
        <Cta
          title="Ready to Streamline Patient Intake?"
          subTitle="Contact us now to optimize patient workflow and alleviate staffing shortages."
          bgUrl="/images/home_3/hero_bg.svg"
          btnUrl="/contact"
          btnText="Contact Us"
        />
      </Section>
  );
}
