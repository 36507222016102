
import React from 'react';
import TextWidget from '../Widget/TextWidget';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import SocialWidget from '../Widget/SocialWidget';
import MenuWidget from '../Widget/MenuWidget';
import NewsletterStyle4 from '../Widget/NewsletterStyle4';
const menuDataOne = [
  { title: 'Home', href: '/' },
  { title: 'Our Approach', href: '/our-approach' },
  { title: 'FAQs', href: '/faq' },
  { title: 'About Us', href: '/about' },
  { title: 'Contact Us', href: '/contact' },
];

const menuDataTwo = [
  {title: 'Streamline Registration', href: '/solutions/#streamline-registration'},
  {title: 'Kiosk Connect', href: '/solutions/#kiosk-connect'},
  {title: 'Januity Insights', href: '/solutions/#januity-insights'},
  {title: 'Januity CareConnect', href: '/solutions/#care-connect'},
  {title: 'Smart Scheduling', href: '/solutions/#smart-scheduling'},
];
const menuDataThree = [
  { title: 'Privacy Policy', href: '/' },
  { title: 'Terms and Conditions', href: '/blog' },
];

export default function FooterStyle4() {
  return (
    <footer className="cs_footer cs_style_2 cs_type_1  cs_white_color ">
      <div className="container">
        
        <div className="cs_footer_in">
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <TextWidget
                logoUrl="/images/footer_logo_white.svg"
                text="Transforming healthcare with <br>innovative and efficient solutions"
              />
             
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <h5 className='cs_white_color'>Quick Links</h5>
              <MenuWidget data={menuDataOne} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <h5 className='cs_white_color'>Our Solutions</h5>
              <MenuWidget data={menuDataTwo} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
            <h5 className='cs_white_color'>Contact</h5>
            <ContactInfoWidget />
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom">
        <div className="container">
          <div className="cs_copyright">
            Copyright © 2024 Januity Health. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
