import React from 'react';
import PageBanner from './Sections/Common/PageBannerCenter';
import ContactForm from './Sections/Contact/ContactForm';
import Section from '../../Section';
import ContactInfoSection from './Sections/Contact/ContactInfoSection';
import { pageTitle } from '../../../helpers/PageTitle';

export default function Contact() {
  pageTitle('Contact');
  return (
    <>
      <PageBanner
        bgUrl="/images/contact/banner_bg.svg"
      //  imgUrl="/images/contact/banner_img.png"
        title="Contact Us"
        subTitle="Empower yourself to reach out for a better healthcare journey"
      />
      <div className="container cs_mt_minus_110">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <ContactForm />
          </div>
        </div>
      </div>
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="Find Us Here" />
      </Section>
    </>
  );
}
