import React from 'react';
import IconBoxStyle11 from '../../../../IconBox/IconBoxStyle11';
import Spacing from '../../../../Spacing';

export default function ContactInfoSection({ sectionTitle }) {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0 text-center">{sectionTitle}</h2>
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Phone"
            subTitle="802-647-3373"
            iconSrc="/images/contact/icon_1.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Email"
            subTitle="info@januity.com"
            iconSrc="/images/contact/icon_2.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Location"
            subTitle="1400 112th Ave SE, Bellevue, WA 98004"
            iconSrc="/images/contact/icon_3.svg"
          />
        </div>
      </div>
      <Spacing md="35" />
      {/* Start Google Map */}
      <div className="cs_map">
        <iframe
          id="map"
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3145.835563711163!2d-122.19307941300869!3d47.59804407118902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906c741edb857d%3A0x38a7bf1ec1b52de2!2sCareage%20Building%2C%201400%20112th%20Ave%20SE%2C%20Bellevue%2C%20WA%2098004!5e0!3m2!1sen!2sus!4v1717082918521!5m2!1sen!2sus"
          allowFullScreen
        />
      </div>
      {/* End Google Map */}
    </div>
  );
}
