import React from 'react';

export default function ContactForm() {
  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <form action="https://formspree.io/f/mwkgdnaj" method="POST">
        <div className="row">
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Name</label>
            <input
              type="text" name='name' required
              className="cs_form_field"
              placeholder="David John"
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Email</label>
            <input
              type="email"
              name='email' required
              className="cs_form_field"
              placeholder="example@gmail.com"
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Subject</label>
            <input
              type="text"
              name='subject' required
              className="cs_form_field"
              placeholder="Your subject"
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Message</label>
            <textarea
              cols={30}
              rows={10}
              className="cs_form_field"
              name='message' required
              placeholder="Write something..."
              defaultValue={''}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-12">
            <div className="cs_height_18" />
            <button className="cs_btn cs_style_1" type="submit">
              <span>Submit</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
