import React from "react";
import PageBanner from "./Sections/Common/PageBanner";
import Ctabottom from "./Sections/Common/Ctabottom";
import SolutionL from "./Sections/Common/SolutionL";
import SolutionR from "./Sections/Common/SolutionR";
import BannerSectionStyle4 from "../../Section/BannerSection/BannerSectionStyle4";
import Section from "../../Section";

import { pageTitle } from "../../../helpers/PageTitle";

export default function Solutions() {
  pageTitle("Solutions");
  return (
    <>
      <PageBanner
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title=" Transforming <br /> Door-to-Doctor Experience "
        subTitle="Solutions that Transform the Patient Care through Innovation and Enhanced Efficiency"
      />

      <Section bottomMd={50} bottomLg={50} bottomXl={100} topMd={100} topLg={50} topXl={100} id="streamline-registration">
        <SolutionL
          imgUrl="/images/solutions/streamline-patient-intake.png"
          title="Streamlined Patient Intake"
          featureList={[
            {
              featureListSubTitle:
                "Januity offers Streamlined Patient Intake which simplifies the admission process by reducing paperwork and wait times. This efficient, HIPAA-compliant system ensures accurate data capture, enabling healthcare professionals to focus on delivering quality care from the start.",
            },
          ]}
        />
      </Section>

      <Section bottomMd={50} bottomLg={50} bottomXl={100} id="dynamic-optimization">
        <SolutionR
          imgUrl="/images/solutions/Clinic-staff-optimization.png"
          title="Clinic Staff Optimization"
          featureList={[
            {
              featureListSubTitle:
                "Januity provides a clinic staff optimization process by adapting to the needs of medical professionals. These solutions streamline administrative tasks, enhance efficiency, and free up valuable time, allowing clinicians to concentrate on providing exceptional patient care. By reducing bottlenecks and improving workflow, Januity ensures a more effective and responsive healthcare environment.",
            },
          ]}
        />
      </Section>
      
      <Section bottomMd={50} bottomLg={50} bottomXl={100} id="smart-scheduling">
        <SolutionL
          imgUrl="/images/solutions/januity-smart-scheduling.png"
          title="Januity Smart Scheduling"
          featureList={[
            {
              featureListSubTitle:
                "Januity Smart Scheduling enhances efficiency by effortlessly managing patient bookings and reducing administrative burdens. This system minimizes no-shows and optimizes time management for both patients and healthcare providers, ensuring a smooth and reliable scheduling process. By automating reminders and confirmations, Januity helps maintain a full appointment calendar, allowing clinicians to focus on delivering quality care without the hassle of manual scheduling.",
            },
          ]}
        />
      </Section>
      <Section bottomMd={50} bottomLg={50} bottomXl={100} id="kiosk-connect">
        <SolutionR
          imgUrl="/images/solutions/kiosk-connect.png"
          title="Kiosk Connect"
          featureList={[
            {
              featureListSubTitle:
                "The Kiosk Connect revolutionizes healthcare operations by efficiently collecting a wide range of clinical-grade vitals from patients. Seamlessly integrated into electronic medical records (EMRs) through a sophisticated cloud-based infrastructure, this innovative system enhances operational efficiency across healthcare facilities.",
            },
          ]}
        />
      </Section>

      
      <Section bottomMd={50} bottomLg={50} bottomXl={100} id="januity-insights">
      <SolutionL
          imgUrl="/images/solutions/januity-insights.png"
          title="Januity Insights"
          featureList={[
            {
              featureListSubTitle:
                "Januity Insights provide actionable revelations through detailed data analysis, empowering healthcare professionals to make informed decisions and drive continuous improvement. This feature helps identify trends, monitor performance, and optimize clinical outcomes, ultimately enhancing the quality of patient care and operational efficiency.",
            },
          ]}
        />
      </Section>

      <Section bottomMd={100} bottomLg={100} bottomXl={100} id="ihr">
            <SolutionR
              imgUrl="/images/solutions/ihr.png"
              title="Integrated Health Records"
              featureList={[
                {
                  featureListSubTitle:
                    "Januity offers Integrated Health Records which helps in real-time access to comprehensive patient data, enhancing care coordination and informed decision-making. This centralized system reduces errors and improves collaboration among healthcare professionals, leading to better patient outcomes and personalized care.",
                },
              ]}
            />
      </Section>    
      
      <Section bottomMd={100} bottomLg={100} bottomXl={100} id="patient-satisfaction">
      <SolutionL
          imgUrl="/images/solutions/patient-centered-satisfaction-solution.png"
          title="Patient-Centered Satisfaction Solution"
          featureList={[
            {
              featureListSubTitle:
                "Januity streamlines patient experiences by minimizing manual tasks during clinical check-ins and reducing lobby wait times. Through self-performed check-in and intake procedures in the lobby, patients spend less than five minutes waiting, significantly enhancing their overall experience.",
            },
          ]}
        />
      </Section>

      <Section bottomMd={100} bottomLg={100} bottomXl={100} id="care-connect">
            <SolutionR
              imgUrl="/images/solutions/januity-care-connect.png"
              title="Januity CareConnect"
              featureList={[
                {
                  featureListSubTitle:
                    "Januity Care Connect effortlessly links patients and healthcare providers, providing convenient virtual consultations and remote monitoring for accessible, high-quality care from any location. Utilizing Januity's kiosk, patients seamlessly manage their health needs with ease.",
                },
              ]}
            />
      </Section> 
     
      <Section bottomMd={100} bottomLg={100} bottomXl={100} id="simplified-payments">
      <SolutionL
          imgUrl="/images/solutions/simplified-payments.png"
          title="Januity Simplified Payments"
          featureList={[
            {
              featureListSubTitle:
                "Januity Simplified Payments streamlines the payment process, providing a hassle-free experience for patients and ensuring efficient financial transactions within healthcare facilities. Moreover, the payment methods are flexible, accommodating various preferences and needs.",
            },
          ]}
        />
      </Section>
    

      

      <Ctabottom />
    </>
  );
}
