import React, { useState } from "react";
import SectionHeading from "../../../../SectionHeading";
import Spacing from "../../../../Spacing";
import Accordion from "../../../../Accordion";
import Section from '../../../../Section';
import FeaturesSectionStyle3 from '../../../../Section/FeaturesSection/FeaturesSectionStyle3';
import { Link } from "react-router-dom";


const beforevisit = [
  {
    title: 'Pre-Appointment Registration',
    subTitle:
      'Pre-Appointment Registration helps streamline the check-in process by allowing patients to complete necessary paperwork and provide essential information before their scheduled visit.',
     iconUrl: '/images/icons/schedule.svg',
  },
  {
    title: 'Appointment Confirmation and Reminder Messages',
    subTitle:
      'Appointment Confirmation and Reminder Messages ensure that patients are aware of their upcoming appointments and help reduce no-show rates by providing timely notifications.',
     iconUrl: '/images/departments/icon_10.svg',
  },
  {
    title: 'Insurance and Identity Capture',
    subTitle:
      'Insurance and Identity Capture streamlines the patient check-in process by securely collecting and verifying insurance details and identification information prior to appointments.',
     iconUrl: '/images/home_1/ID Card.svg',
  },
  {
    title: 'Electronic Acknowledgement of Consent Forms',
    subTitle:
      'Utilizing Electronic Acknowledgement of Consent Forms streamlines the procedure for obtaining and recording consent, improving efficiency and ensuring compliance across diverse contexts.',
     iconUrl: '/images/departments/icon_12.svg',
  },
];
const duringvisit=[
  {
    title: 'Onsite Check-In, Registration and Payments',
    subTitle:'Onsite Check-In and Registration expedite patient intake by enabling completion of paperwork and information submission upon arrival. Direct Payment Selections streamline transactions with various methods, such as credit cards, mobile wallets, or electronic transfers, ensuring quick, convenient payment processing.',
    iconUrl: '/images/icons/schedule.svg',
  },
  {
    title: 'Kiosk Connect',
    subTitle:'The Kiosk Connect revolutionizes healthcare by efficiently collecting clinical-grade vitals from patients. Seamlessly integrated into EMRs via a sophisticated cloud-based system, it enhances operational efficiency in healthcare facilities.',
    iconUrl: '/images/home_1/kiosk.svg',
  },
  {
    title: 'Patient-Reported Health Outcomes',
    subTitle:'Patient-Reported Health Outcomes provide valuable insights into patients perceptions of their health status, symptoms, and overall well-being, offering healthcare providers a comprehensive understanding of the effectiveness of treatments and interventions from the patients perspective.',
    iconUrl: '/images/departments/icon_11.svg',
  },
  {
    title: 'Electronic Signature for Medical Records',
    subTitle:'Electronic Signature for Medical Records allows healthcare professionals to securely sign and authenticate documents electronically, ensuring compliance with regulatory requirements while streamlining the documentation process in healthcare settings.',
    iconUrl: '/images/departments/icon_12.svg',
  }
];
const aftervisit=[
  {
    title: 'Patient Feedback Surveys',
    subTitle:'Patient Feedback Surveys gather valuable insights from patients about their healthcare experiences, enabling providers to continually improve services and enhance overall patient satisfaction.',
    iconUrl: '/images/departments/direct-admission.svg',
  },
  {
    title: 'After-Visit Payment Choices and Payment Reminders',
    subTitle:'After-Visit Payment Choices offer flexible options like credit cards, mobile wallets, and electronic transfers for patient convenience. Timely Payment Reminders ensure prompt payments, reducing missed payments. This system enhances financial management for both patients and healthcare providers.',
    iconUrl: '/images/departments/online-claim.svg',
  },
  {
    title: 'Follow-up Care Notifications',
    subTitle:'Follow-up Care Notifications ensure patients receive timely reminders for upcoming appointments and necessary medical follow-ups. This system promotes adherence to treatment plans, improving health outcomes. Automated notifications enhance patient engagement and streamline care coordination.',
    iconUrl: '/images/icons/followup.svg',
  },
  {
    title: 'Pertinent Health Related Updates',
    subTitle:'Pertinent Health-related Updates, such as changes in treatment protocols or new medical research findings, are crucial for patients to stay informed and actively participate in managing their healthcare journey.',
    iconUrl: '/images/departments/ask-a-doctor.svg',
  }
];
const betweenvisits = [
  {
    title: 'Focused Educational Engagement',
    subTitle:
      'Focused Educational Engagement ensures that patients receive tailored health information that directly addresses their specific needs and conditions, enhancing their understanding and involvement in their own care.',
     iconUrl: '/images/icons/category.svg',
  },
  {
    title: 'Public Announcements',
    subTitle:
      'Public Announcements in healthcare are vital for communicating essential information about health alerts, vaccination campaigns, and preventive measures to the community, ensuring public safety and well-being.',
     iconUrl: '/images/departments/icon_10.svg',
  },
  {
    title: 'Automated Reminder Notifications',
    subTitle:
      'Automated Reminder Notifications help ensure patients do not miss important appointments or medication schedules by sending timely and consistent alerts directly to their phones or email.',
     iconUrl: '/images/departments/icon_11.svg',
  },
  {
    title: 'Subsequent Inquiries',
    subTitle:
      'Subsequent Inquiries allow healthcare providers to gather additional information from patients after initial consultations, ensuring comprehensive and continuous care.',
     iconUrl: '/images/departments/icon_12.svg',
  },
];

export default function PatientEngagementJourney({
  data,
  sectionTitle,
  sectionTitleUp,
}) {
  const [activeTab, setActiveTab] = useState("beforevisit");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="container">
      <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} center />
      <Spacing md="72" lg="50" />

      <div className="row">
        <div className="col-3 col-xl-3 col-md-6">

            <Link
              className={`cs_iconbox cs_style_custom cs_center ${activeTab === "beforevisit" ? "active" : ""}`}
              onClick={() => handleTabClick("beforevisit")}
              href="#"
            >
            <h2 class="cs_iconbox_title cs_fs_24 cs_semibold m-0">
            Pre-Visit
            </h2>
            </Link>
        </div>

        <div className="col-3 col-xl-3 col-md-6">

            <Link
              className={`cs_iconbox cs_style_custom cs_center ${activeTab === "duringvisit" ? "active" : ""}`}
              onClick={() => handleTabClick("duringvisit")}
              href="#"
            >
            <h2 class="cs_iconbox_title cs_fs_24 cs_semibold m-0">
              At Clinic
            </h2>
            </Link>

        </div>
        
        <div className="col-3 col-xl-3 col-md-6">

            <Link
              className={`cs_iconbox cs_style_custom cs_center ${activeTab === "aftervisit" ? "active" : ""}`}
              onClick={() => handleTabClick("aftervisit")}
              href="#"
            >
              
            <h2 class="cs_iconbox_title cs_fs_24 cs_semibold m-0">
            Post-Visit
            </h2>
            </Link>

        </div>

        <div className="col-3 col-xl-3 col-md-6">

            <Link
              className={`cs_iconbox cs_style_custom cs_center ${activeTab === "betweenvisits" ? "active" : ""}`}
              onClick={() => handleTabClick("betweenvisits")}
              href="#"
            >
            <h2 class="cs_iconbox_title cs_fs_24 cs_semibold m-0">
              Interim Care
            </h2>
            </Link>

        </div>
        
       
        
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="tab-content">
            <div
              className={`tab-pane fade ${
                activeTab === "beforevisit" ? "show active" : ""
              }`}
            >
              <Section topMd={10} topLg={10} topXl={10}>
                <FeaturesSectionStyle3
                  sectionTitle=""
                  sectionTitleUp=""
                  data={beforevisit}
                />
              </Section>

            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "duringvisit" ? "show active" : ""
              }`}
            >
              <Section topMd={10} topLg={10} topXl={10}>
                <FeaturesSectionStyle3
                  sectionTitle=""
                  sectionTitleUp=""
                  data={duringvisit}
                />
              </Section>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "aftervisit" ? "show active" : ""
              }`}
            >
              <Section topMd={10} topLg={10} topXl={10}>
                <FeaturesSectionStyle3
                  sectionTitle=""
                  sectionTitleUp=""
                  data={aftervisit}
                />
              </Section>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "betweenvisits" ? "show active" : ""
              }`}
            >
              <Section topMd={10} topLg={10} topXl={10}>
                <FeaturesSectionStyle3
                  sectionTitle=""
                  sectionTitleUp=""
                  data={betweenvisits}
                />
              </Section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
