import React from 'react';
import Spacing from '../../../../Spacing';
import Section from "../../../../Section";
export default function SolutionsL({
  imgUrl,
  spiningImgUrl,
  title,
  subTitle,
  featureList,
}) {
  return (
    <section className="cs_about cs_shape_wrap cs_style_1 ">
    
      <div className="container">
        <div className="row align-items-center flex-lg-row flex-column-reverse cs_gap_y_40">

        <div className="col-lg-5">
            <div className="cs_about_content">
              <div className="cs_section_heading cs_style_1">
                <h4 className="cs_section_title cs_fs_40 m-0">{title}</h4>
                <div className="cs_height_5" />
            
              </div>
              <Spacing md="40" lg="40" />
              <ul className="cs_about_text_list">
                {featureList.map((item, index) => (
                  <li key={index}>
                    <p className="m-0" dangerouslySetInnerHTML={{ __html: item.featureListSubTitle}}></p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-7">
            <Spacing md="55" />
            <div className="text-end">
              <img src={imgUrl} alt="About" className='float-right' />
            
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}
