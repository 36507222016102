import React from "react";
import PageBanner from "./Sections/Common/PageBanner";
import Ctabottom from "./Sections/Common/Ctabottom";
import SolutionL from "./Sections/Common/SolutionL";
import SolutionR from "./Sections/Common/SolutionR";
import BannerSectionStyle4 from "../../Section/BannerSection/BannerSectionStyle4";
import Section from "../../Section";
import { pageTitle } from "../../../helpers/PageTitle";


export default function OurMissionandVision() {
  pageTitle("OurMissionandVision");

  return (
    <>
      <PageBanner
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title=" Our Mission and Vision <br />"
        subTitle="Pioneering Accessible, Quality Healthcare for Underserved Populations Worldwide through Innovation"
      />

      <Section topMd={50} topLg={50} topXl={50} bottomMd={100} bottomLg={100} bottomXl={100} id="mission">
        <SolutionL
          imgUrl="/about/mission.jpg"
          title="Januity's Mission"
          featureList={[
            {
              featureListSubTitle:
                `At the core of our mission is a commitment to leveraging automation tools and 
                technology-driven innovations to streamline healthcare delivery processes, reduce inefficiencies, 
                 and improve the overall quality of care. <br> 
                \n
                 </br>By automating routine tasks, optimizing workflows, and enhancing communication channels, we aim to empower 
                 healthcare professionals to focus more time and attention on patient care, ultimately improving health outcomes 
                 and optimizing the patient experience. <br> 
                \n
                </br>Through our unwavering dedication to innovation, collaboration, and social impact, 
                 we envision a future where access to quality healthcare is not a privilege but a fundamental human right. 
                 Together, we can build a healthier, more equitable world for all.
                 `
            },
          ]}
        />
      </Section>

      <Section bottomMd={100} bottomLg={100} bottomXl={100} id="vision">
        <SolutionR
          imgUrl="/images/home_1/about.png"
          title="Januity's Vision"
          featureList={[
            {
              featureListSubTitle:
                `At Januity, we are committed to pioneering a transformative movement in healthcare worldwide. Through cutting-edge technology and advanced data analytics, we aim to break down barriers and 
                  extend access to quality care for underserved populations. Our mission is to revolutionize healthcare delivery, ensuring that every individual receives the attention and support they deserve, regardless of their location or 
                  socioeconomic status. <br>
                  </br>With a focus on addressing disparities in healthcare access, we deploy scalable and sustainable solutions, 
                  empowered by collaborative partnerships with local communities and organizations. By harnessing insights from healthcare data analytics, we optimize resource allocation and drive informed decision-making to enhance impact and outcomes, ultimately bridging the gap in healthcare
                  access and improving lives globally.`,
            },
          ]}
        />
      </Section>

      <Ctabottom />
      
      </>
)
}
